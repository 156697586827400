body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 auto;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}

ul {
  list-style-type: none;
}

li {
  padding: 3px 6px;
  color: red;
  display: flex;
  width: 300px;
}

li.complete {
  color: green;
  text-decoration: line-through;
}

li  button {
  padding: 3px 6px;
  margin: 6px
}

li label {
  flex: 1;
}

.counter {
  display: flex;
  align-items: stretch;
  font-weight: bold;
  padding-bottom: 12px;
}
.counter span{
  padding: 3px;
  width: 200px;
  text-align: center;
}

.error {
  color: red;
  padding-bottom: 20px;
}

#user-panel {
  position: absolute;
  top: 24px;
  right: 24px;
}

#user-name {
  display: block;
  white-space: nowrap;
  position: absolute;
  top: 40px;
  right: 12px;
}

#user-panel button {
  width: 80px;
  margin: 12px;
}